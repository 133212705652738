<template>
<p>Hallo</p>
</template>

<script>
import apiAxios from "@/libs/apiAxios";
import user from "@/store/modules/user";
import router from "@/router";

export default {
  name: "accessToken",
  data(){
    return {
      authToken:"",
      serviceToken:"",
      service:"",
      goto:"/"
    }
  },
  created() {
    this.getTokens();

  },
  methods: {
    async setTokens() {

      await user.actions.getUserDataByToken(this.authToken,this.serviceToken)
          .then(response => {
            console.log(response);
            let url=this.goto;
            switch(this.service)
            {
              case 'forgot':
                url='/changePassword';
                break;
            }

            router.push(url);

          });
    },

    async getTokens() {
      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('goto' in getVars)
        {
          this.goto=decodeURI(getVars.goto);
        }

        // do
        await apiAxios.get('/account/tokens?secret='+getVars.secret, {})
            .then(response => {
               console.log(response);
              //commit('SET_USER', response.data.UserResult)
              this.authToken=response.data.UserTokenResult.authToken;
              this.serviceToken=response.data.UserTokenResult.servToken;
              this.service=response.data.UserTokenResult.service;
              this.setTokens();
            })
            .catch((error) => console.log(error));


      }
    }
  }
}
</script>

